<template>
    <div class="model-library-page">
        <div>
            <el-form size="small" :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item label="名称：">
                    <el-input style="width: 200px" v-model="searchForm.search_title" clearable placeholder="请输入贴图名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="编号：">
                    <el-input style="width: 200px" v-model="searchForm.search_number" clearable
                        placeholder="请输入贴图编号"></el-input>
                </el-form-item>
                <el-form-item style="margin-left: 20px;float: right;">
                    <div class="btn" @click="handleSearch">查询</div>
                </el-form-item>
            </el-form>
            <div class="model-library-content-wrap">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="待审核" name="toBeReviewed"></el-tab-pane>
                    <el-tab-pane label="已通过" name="passed"></el-tab-pane>
                    <el-tab-pane label="已驳回" name="rejected"></el-tab-pane>
                </el-tabs>
                <div class="waitAudit-content-wrap">
                    <ul class="content-list">
                        <li class="content-item" v-for="item in chartletList" :key="item.id">
                            <div class="top-wrap">
                                <span>编号：{{ item.number }}</span>
                                <div>
                                    <el-button v-if="item.status == 0" size="small" type="text"
                                        @click="auditBtn(item)">审核
                                    </el-button>
                                    <el-button size="small" type="text"
                                        @click="download(item.picture, item.title)">下载</el-button>
                                </div>
                            </div>
                            <div class="image-wrap">
                                <img :src="item.picture" alt="" />
                            </div>
                            <div class="bottom-wrap">
                                <p class="class-name">分类：{{ getClassifyName(item.classify_map) }}</p>
                                <p class="model-name">贴图名称：{{ item.title }}</p>
                                <p class="brand-name">企业名称：{{ item.e_name }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div style="text-align: right; margin-top: 30px">
                <el-pagination v-model:currentPage="page.page" :page-sizes="[10, 15, 30, 50]" :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.count" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" title="审核" width="30%" center>
            <div style="text-align: center;">
                <el-radio-group v-model="radio1">
                    <el-radio label="1" size="large">通过</el-radio>
                    <el-radio label="-1" size="large">驳回</el-radio>
                    <el-radio label="3" size="large">修改分类</el-radio>
                </el-radio-group>
                <div v-if="radio1 == '-1'" style="margin-top: 20px;">
                    <el-input v-model="textarea" :rows="2" type="textarea" placeholder="请输入驳回原因"/>
                </div>
                <div v-if="radio1 == '3'" style="margin-top: 20px;">
                    <el-cascader v-model="targetClassify" :options="options"
                                 :props="{ label: 'name', value: 'id' }"
                                 style="width: 400px;">
                    </el-cascader>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false;targetClassify=[]">取消</el-button>
                    <el-button type="primary" @click="btnOK">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {
    getMappingauditList,
    mappingchangeStatus, mappingClassifyAccept,
    modelClassifyAccept,
    texturesConfig_list
} from '@/common/api/model/modelAudit/index.js';

export default {
    name: 'chartletAudit',
    data() {
        return {
            options: [],
            newClassifyFlag: false, // 是否新分类
            classifyInfo: '', // 拼接通过新分类所需字段
            targetClassify: [],//目标修改分类
            textureConfigList: [],// MX已有 贴图分类
            id: '',
            chartletList: [], // 贴图列表
            searchForm: { // 搜索
                search_status: '',
                search_title: '',
                search_number: ''
            },
            // 驳回文本
            textarea: '',
            // 按钮
            radio1: '1',
            dialogVisible: false,
            activeName: 'toBeReviewed',
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
        };
    },
    watch: {
        dialogVisible() {
            if (this.dialogVisible == false) {
                this.radio1 = '1'
            }
        }
    },
    mounted() {
        this.getList()
        this.getMXTextureClassify()
    },
    methods: {
        getClassifyName(val) {
            let arr = []
            val.forEach(item => {
                arr.push(item.name)
            })
            return arr.join('>')
        },
        getMXTextureClassify() {
            texturesConfig_list().then(res => {
                let { code, classify_list } = res
                if (code == 200) {
                    this.textureConfigList = classify_list
                    let transfer = (configList) => { // 供el级联下拉框使用
                        let arr = configList
                        for (let i = 0; i < arr.length; i++) {
                            if (arr[i].children && arr[i].children.length != 0) {
                                transfer(arr[i].children)
                            } else {
                                arr[i].children = ''
                            }
                        }
                        return arr
                    }
                    this.options = transfer(this.textureConfigList)
                }
            })

        },
        getList() {
            let dt = {
                search_status: this.searchForm.search_status,
                search_title: this.searchForm.search_title,
                search_number: this.searchForm.search_number,
                page: this.page.page,
                limit: this.page.pageSize
            }
            getMappingauditList(dt).then(res => {
                let { code, result } = res
                if (code == 200) {
                    this.chartletList = result.data;
                    this.page.count = result.total
                }
            })
        },
        handleClick() {
            if (this.activeName == 'passed') {
                this.searchForm.search_status = 1
            } else if (this.activeName == 'rejected') {
                this.searchForm.search_status = -1
            } else {
                this.searchForm.search_status = ''
            }
            this.getList()
        },
        // 审核按钮
        auditBtn(item) {
            this.id = item.id
            this.classifyInfo = item.classify_map.map(item => {
                return item.id;
            }).join('|');
            this.newClassifyFlag = item.classify_map.some(item => {
                return item.status != 1;
            });
            this.dialogVisible = true
        },
        btnOK() {
            if (this.radio1 == 1 && this.newClassifyFlag) { //
                mappingClassifyAccept({classify_ids: this.classifyInfo}).then(res => {
                    let {code} = res
                    if (code != 200) {
                        this.$message.error('新分类通过失败')
                        return
                    }
                })
            }
            let dt = {
                id: this.id,
                status: this.radio1,
                reason: this.textarea,
                // 取this.targetClassify最后一个
                suggestion_classify_id: this.targetClassify.at(-1)
            }
            mappingchangeStatus(dt).then(res => {
                let { code, msg } = res
                if (code == 200) {
                    this.$message.success(msg)
                    this.dialogVisible = false
                    this.getList()
                }
            })
        },
        // 下载贴图
        download(url, fileName) {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob"; // 通过文件下载url拿到对应的blob对象
            xhr.onload = () => {
                if (xhr.status === 200) {
                    let link = document.createElement("a");
                    // let body = document.querySelector("body");
                    link.href = window.URL.createObjectURL(xhr.response);
                    link.download = fileName + '.' + url.split('.')[url.split('.').length - 1];
                    link.click();
                    // body.removeChild(link);
                    window.URL.revokeObjectURL(link.href);
                }
            }
            xhr.send();
        },
        // 处理查询按钮
        handleSearch() {
            this.page.page = 1;
            this.getList();
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.page.page = 1;
            this.getList();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.getList();
        }
    }
};
</script>
<style scoped>
ul,
p {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

.model-library-page {
    box-sizing: border-box;
    padding: 15px;
    background-color: #fff;
}

.model-library-page .btn {
    display: inline-block;
    width: 90px;
    height: 35px;
    background-color: rgba(85, 85, 85, 1);
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
}

.model-library-page .btn:first-child {
    margin-right: 20px;
}

.model-library-page .btn:hover {
    color: rgb(254, 154, 35);
}

.model-library-content-wrap {
    margin-top: 10px;
}

.content-action-left {
    text-align: end;
}

.auditRecord-content-wrap,
.waitAudit-content-wrap {
    margin-top: 20px;
}

.auditRecord-content-wrap .content-list,
.waitAudit-content-wrap .content-list {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #7f7f7f;
}

.content-list .content-item {
    position: relative;
    box-sizing: border-box;
    width: 265px;
    height: 375px;
    margin: 0 21.5px 20px;
    padding: 5px 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
}

.content-list .content-item:hover .reject-reason-wrap {
    display: block;
}

.top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 0 5px;
}

.top-wrap,
.top-wrap :deep(.el-button) {
    font-size: 14px;
}

.image-wrap {
    position: relative;
    width: 235px;
    height: 215px;
    margin: 5px auto;
}

.image-wrap img {
    width: 100%;
    height: 100%;
}

.prompt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 145px;
    height: 25px;
    margin: auto;
    border: 1px solid rgb(217, 0, 27);
    line-height: 25px;
    text-align: center;
    color: rgb(217, 0, 27);
}

.enable {
    border: 1px solid #04f21c !important;
    color: #04f21c !important;
}

.bottom-wrap {
    box-sizing: border-box;
    margin-top: 15px;
    padding-left: 5px;
    font-size: 14px;
}

.bottom-wrap .model-name,
.bottom-wrap .brand-name,
.reject-reason-wrap .reason-desc {
    margin-top: 15px;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.reject-reason-wrap {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 70px;
    width: 237px;
    height: 70px;
    margin: 5px auto;
    border-radius: 2px;
    background-color: #fff;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.reject-reason-wrap .reject-reason {
    color: #02a7f0;
    margin-top: 10px;
}

.reject-reason-wrap .reason-desc {
    box-sizing: border-box;
    display: block;
    padding: 0 5px;
}
</style>
